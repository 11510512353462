import { extendTheme } from '@chakra-ui/react';

const koltinTheme = extendTheme({
  colors: {
    primary: '#1A1B24',
    secondary: '#EFF0EA',
    gray: {
      50: '#848484',
      150: '#F4F4F0',
      250: '#D8DBCC',
    },
    orange: {
      150: '#F16158',
    },
    green: {
      50: '#C4E3CD',
      150: '#4AAF66',
    },
    brown: {
      950: '#4A3749',
    },
  },
  fonts: {
    heading: `'Heading Font Name', sans-serif`,
    body: `'Body Font Name', sans-serif`,
  },
});

export default koltinTheme;
