import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { OTPInput } from 'chakra-otp-input';
import Input from 'react-phone-number-input/input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { otpLogin, otpStart } from '../services';
import Layout from '../components/Layout/Layout';
import { StoreContext } from '../store';

const Auth = () => {
  const toast = useToast();

  const { isAuthenticated } = useContext(StoreContext);

  const [formState, setFormState] = useState({
    cellphone: '',
    otp: '',
    otpAvailable: false,
    isSubmitting: false,
  });

  const [formFieldsErrors, setFormFieldsError] = useState({
    cellphoneInputHasError: false,
    cellphoneInputMessage: undefined,
    OTPInputHasError: false,
    OTPInputErrorMessage: undefined,
  });

  const onChange = e => {
    setFormState({ ...formState, cellphone: e });
  };

  const handleOtpOnChange = e => {
    setFormState({ ...formState, otp: e });
    const { otp } = formState;
    if (otp.length === 6) {
      setFormFieldsError({ ...formFieldsErrors, OTPInputHasError: false });
    }
  };

  const requestOtp = async cellphone => {
    if (cellphone && isPossiblePhoneNumber(cellphone)) {
      setFormState({ ...formState, isSubmitting: true, otpAvailable: false });
      try {
        await otpStart({ cellphone });
        toast({
          title: `Se envió el código de confirmación al celular ${cellphone}`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setFormState({ ...formState, isSubmitting: false, otpAvailable: true });
      } catch (error) {
        setFormState({ ...formState, isSubmitting: false });

        let errorMessage = 'Algo anda mal, inténtalo de nuevo por favor.';

        if (error.code === 'bad.connection') {
          errorMessage =
            'Verifica el número de teléfono e intenta de nuevo por favor';
        }

        toast({
          title: `${error?.error_description || errorMessage}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const login = async ({ cellphone, otp }) => {
    setFormState({ ...formState, isSubmitting: true });
    try {
      await otpLogin({ cellphone, otp });
    } catch (error) {
      toast({
        title: `${
          error?.error_description ||
          'Algo anda mal, inténtalo de nuevo por favor.'
        }`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    setFormState({ ...formState, isSubmitting: false });
  };

  const validateOTP = () => {
    if (!formState.otp) {
      setFormFieldsError({
        ...formFieldsErrors,
        OTPInputErrorMessage: 'Código de confirmación requerido',
        OTPInputHasError: true,
      });
      return false;
    }

    if (formState.otp.length < 6) {
      setFormFieldsError({
        ...formFieldsErrors,
        OTPInputErrorMessage: 'Código de confirmación inválido',
        OTPInputHasError: true,
      });
      return false;
    }

    return true;
  };

  const validateCellphone = () => {
    if (!formState.cellphone) {
      setFormFieldsError({
        ...formFieldsErrors,
        cellphoneInputMessage: 'Celular requerido',
        cellphoneInputHasError: true,
      });
      return false;
    }

    // eslint-disable-next-line no-unused-expressions
    formState.cellphone && isPossiblePhoneNumber(formState.cellphone)
      ? setFormFieldsError({
          ...formFieldsErrors,
          cellphoneInputMessage: '',
          cellphoneInputHasError: false,
        })
      : setFormFieldsError({
          ...formFieldsErrors,
          cellphoneInputMessage: 'Celular inválido',
          cellphoneInputHasError: true,
        });
  };

  const onSubmit = async event => {
    event.preventDefault();
    const { cellphone, otp, otpAvailable } = formState;

    if (otpAvailable) {
      if (validateOTP()) {
        await login({ cellphone, otp });
        return;
      }
      return;
    }
    validateCellphone();
    await requestOtp(cellphone);
  };

  const onBlur = () => {
    validateCellphone();
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Layout>
      <Box
        margin="0 auto"
        maxWidth={['22rem', '22rem', '25rem', '25rem']}
        textAlign="center"
      >
        <form onSubmit={onSubmit} method="POST">
          <Box
            width="100%"
            padding="1rem 2rem 2rem"
            borderRadius="5px"
            marginBottom="5px"
          >
            <Text display="flex" justifyContent="center">
              <Link
                textAlign="center"
                as={RouterLink}
                to="/"
                _hover={{ textDecoration: 'none' }}
                _focus={{ boxShadow: 'none' }}
              />
            </Text>
            <FormControl
              mb={5}
              textAlign="left"
              isInvalid={formFieldsErrors.cellphoneInputHasError}
            >
              <Box d="flex" justifyContent="space-between" alignItems="center">
                <FormLabel htmlFor="cellphone">Número de teléfono</FormLabel>
              </Box>
              <Input
                className={`phone-input${
                  formFieldsErrors.cellphoneInputHasError ? ' invalid' : ''
                }`}
                country="MX"
                placeholder="Número de teléfono"
                onChange={onChange}
                onBlur={onBlur}
                name="cellphone"
                id="cellphone"
                value={formState.cellphone}
                maxLength="16"
                international
                withCountryCallingCode
                readOnly={formState.otpAvailable}
              />
              <FormErrorMessage>
                {formFieldsErrors.cellphoneInputMessage}
              </FormErrorMessage>
            </FormControl>
            {formState.otpAvailable && (
              <FormControl
                mb={8}
                textAlign="left"
                className="otp-input"
                isInvalid={formFieldsErrors.OTPInputHasError}
              >
                <FormLabel htmlFor="otp">
                  Introduce el código de confirmación
                </FormLabel>
                <OTPInput
                  value={formState.otp}
                  onChange={handleOtpOnChange}
                  noInputs={6}
                  isDisabled={false}
                  isNumeric
                />
                <FormErrorMessage>
                  {formFieldsErrors.OTPInputErrorMessage}
                </FormErrorMessage>
              </FormControl>
            )}
            <Button
              mt={4}
              variant="solid"
              bg="primary"
              type="submit"
              w="100%"
              color="white"
              isLoading={formState.isSubmitting}
              isDisabled={formState.isSubmitting}
              _active={{
                bg: 'primary',
              }}
              _hover={{
                bg: 'primary',
              }}
            >
              <span>{formState.otpAvailable ? 'Login' : 'Continuar'}</span>
            </Button>
          </Box>
        </form>
      </Box>
    </Layout>
  );
};

export default Auth;
