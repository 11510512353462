import React from 'react';
import { Box, Center, Heading, Text, VStack, HStack } from '@chakra-ui/react';
import HeartIcon from './HeartIcon';

export default function UpperPanel() {
  return (
    <Box bg="gray.150" height="13rem" borderY="1px" borderColor="gray.250">
      <VStack spacing={4} align="stretch" padding="20px">
        <Box h="40px">
          <Center h="40px">
            <Heading as="h4" size="md">
              ¡Bienvenido a Koltin®!
            </Heading>
          </Center>
        </Box>
        <Box>
          <HStack>
            <Box display="flex">
              <HeartIcon paddingY="0" />
            </Box>
            <Box>
              <Heading as="h6" size="sm">
                Estudios médicos
              </Heading>
              <Text size="1rem" color="gray.50">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint.
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box h="40px">
          <Center h="40px">
            <svg
              width="32"
              height="8"
              viewBox="0 0 32 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4" cy="4" r="4" fill="#1A1B24" />
              <circle
                opacity="0.32"
                cx="16"
                cy="4"
                r="3.5"
                fill="#F4F4F1"
                stroke="#1A1B24"
              />
              <circle
                opacity="0.32"
                cx="28"
                cy="4"
                r="3.5"
                fill="#F4F4F1"
                stroke="#1A1B24"
              />
            </svg>
          </Center>
        </Box>
      </VStack>
    </Box>
  );
}
