import React, { useContext } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import Auth from './pages/Auth';
import Authorize from './pages/Authorize';
import Dashboard from './pages/Dashboard';
import InvoiceHistory from './pages/InvoiceHistory';
import { StoreContext, StoreProvider } from './store';

function App() {
  return (
    <StoreProvider>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="authorize" element={<Authorize />} />
          <Route path="/dashboard">
            <Route
              index
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              index={false}
              path="invoices"
              element={
                <RequireAuth>
                  <InvoiceHistory />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </StoreProvider>
  );
}

// eslint-disable-next-line react/prop-types
function RequireAuth({ children }) {
  const { isAuthenticated } = useContext(StoreContext);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

export default App;
