import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import UpperPanel from '../UpperPanel/UpperPanel';

// eslint-disable-next-line react/prop-types
const Layout = ({ children, showUpperPanel = true, showFooter = true }) => (
  <>
    <Header />
    {showUpperPanel && <UpperPanel />}
    <main>{children}</main>
    {showFooter && <Footer />}
  </>
);

export default Layout;
