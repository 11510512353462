import React from 'react';

const Logo = () => (
  <svg
    width="77"
    height="21"
    viewBox="0 0 77 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00585938 20.1711C0.00585938 20.4634 0.248506 20.7026 0.545075 20.7026H2.53478C2.83134 20.7026 3.07399 20.4634 3.07399 20.1711V15.2971C3.07399 15.0048 2.90146 14.9357 2.69117 15.143L0.388708 17.4125C0.178414 17.6198 0.00585938 18.0291 0.00585938 18.3214V20.1711Z"
      fill="white"
    />
    <path
      d="M6.02276 11.8522C5.81247 12.0595 5.79091 12.4156 5.97964 12.6441L12.1698 20.2872C12.3532 20.5158 12.7468 20.7018 13.0434 20.7018H15.8311C16.1277 20.7018 16.2193 20.5158 16.036 20.2872L8.1311 10.5181C7.94777 10.2895 7.62422 10.2736 7.41393 10.4809L6.02276 11.8522Z"
      fill="white"
    />
    <path
      d="M8.8701 7.2285C8.8701 7.2285 9.03728 7.05311 9.24218 6.84051L15.3407 0.388003C15.5402 0.1754 15.4647 0 15.1682 0H12.4451C12.1485 0 11.7387 0.1754 11.5392 0.388003L3.43481 8.97717C3.2353 9.18977 3.06816 9.12599 3.06816 8.83366V0.531508C3.06816 0.239178 2.82551 0 2.52894 0H0.539216C0.242647 0 0 0.239178 0 0.531508V15.4297C0 15.722 0.172554 15.7911 0.382848 15.5838L8.48188 7.60056C8.69217 7.39327 8.86471 7.22319 8.8701 7.2285Z"
      fill="white"
    />
    <path
      d="M29.9859 7.00022C29.3065 6.34115 28.5085 5.85216 27.5864 5.52263C26.6643 5.19309 25.656 5.02832 24.5722 5.02832C23.483 5.02832 22.4747 5.20904 21.5364 5.57046C20.6036 5.93189 19.7839 6.45808 19.0884 7.14904C18.3874 7.84 17.8374 8.69041 17.4384 9.69496C17.0394 10.7048 16.8398 11.8529 16.8398 13.1444C16.8398 14.4148 17.034 15.5362 17.4222 16.5142C17.8104 17.4869 18.3443 18.3107 19.0237 18.9804C19.7031 19.6501 20.5011 20.1497 21.4232 20.4899C22.3452 20.83 23.3536 21.0001 24.4374 21.0001C25.5266 21.0001 26.5349 20.8194 27.4732 20.458C28.406 20.0966 29.2256 19.5651 29.9212 18.8635C30.6222 18.1619 31.1722 17.3008 31.5712 16.2857C31.9702 15.2652 32.1697 14.1118 32.1697 12.8202C32.1697 11.5499 31.9756 10.4284 31.5874 9.45047C31.1992 8.47249 30.6653 7.65397 29.9859 7.00022ZM28.7188 15.7541C28.4653 16.4876 28.1256 17.0776 27.6996 17.5294C27.2791 17.9812 26.7884 18.2948 26.233 18.4808C25.6776 18.6668 25.1114 18.7625 24.5344 18.7625C23.9575 18.7625 23.3913 18.6509 22.8359 18.433C22.2805 18.215 21.7844 17.8749 21.3531 17.4125C20.9217 16.95 20.5712 16.3548 20.3016 15.6213C20.0374 14.8878 19.9026 14.0055 19.9026 12.9744C19.9026 11.8582 20.032 10.9334 20.2854 10.1999C20.5389 9.46641 20.8786 8.88176 21.3045 8.4406C21.7251 8.00477 22.2158 7.69649 22.7712 7.52109C23.3266 7.3457 23.8928 7.26065 24.4697 7.26065C25.0467 7.26065 25.6129 7.36696 26.1683 7.57424C26.7237 7.78153 27.2197 8.11638 27.6511 8.57879C28.0825 9.04121 28.433 9.63118 28.7026 10.354C28.9668 11.0769 29.1016 11.9539 29.1016 12.985C29.107 14.0958 28.9776 15.0207 28.7188 15.7541Z"
      fill="white"
    />
    <path
      d="M35.8691 20.1709C35.8691 20.4633 36.1118 20.7024 36.4084 20.7024H38.1986C38.4951 20.7024 38.7378 20.4633 38.7378 20.1709V0.829359C38.7378 0.53703 38.4951 0.297852 38.1986 0.297852H36.4084C36.1118 0.297852 35.8691 0.53703 35.8691 0.829359V20.1709Z"
      fill="white"
    />
    <path
      d="M50.6056 18.1878C50.0718 18.2887 49.5812 18.3366 49.139 18.3366C48.5189 18.3366 48.012 18.2037 47.6238 17.9433C47.2356 17.6828 47.1223 16.9228 47.1223 16.9228C47.0792 16.6358 47.0415 16.1574 47.0415 15.8651V7.95624C47.0415 7.66391 47.2841 7.42473 47.5807 7.42473H51.1017C51.3983 7.42473 51.641 7.18555 51.641 6.89322V5.78768C51.641 5.49535 51.3983 5.25618 51.1017 5.25618H47.5807C47.2841 5.25618 47.0415 5.017 47.0415 4.72467V0.860609C47.0415 0.56828 46.7988 0.329102 46.5022 0.329102H44.6797C44.3831 0.329102 44.1405 0.56828 44.1405 0.860609V4.72467C44.1405 5.017 43.8978 5.25618 43.6012 5.25618H42.3449C42.0483 5.25618 41.8057 5.49535 41.8057 5.78768V6.89322C41.8057 7.18555 42.0483 7.42473 42.3449 7.42473H43.6012C43.8978 7.42473 44.1405 7.66391 44.1405 7.95624V16.2584C44.1405 16.5507 44.1782 17.0238 44.2213 17.3161C44.2213 17.3161 44.491 19.0329 45.2027 19.6972C45.9145 20.3669 47.0468 20.7018 48.6052 20.7018C49.3601 20.7018 50.0826 20.638 50.7728 20.5051C51.463 20.3723 51.9591 20.2128 51.9591 20.2128C52.2395 20.1224 52.4228 19.8142 52.3635 19.5272L52.1155 18.3685C52.0561 18.0815 51.7704 17.9167 51.4846 17.9964C51.4846 17.9858 51.1395 18.0868 50.6056 18.1878Z"
      fill="white"
    />
    <path
      d="M57.4692 1.45117C56.957 1.45117 56.5202 1.62657 56.1535 1.97737C55.7869 2.32816 55.6035 2.75337 55.6035 3.2583C55.6035 3.76323 55.7869 4.18312 56.1535 4.52328C56.5202 4.86345 56.957 5.03354 57.4692 5.03354C57.9599 5.03354 58.3913 4.86345 58.7687 4.52328C59.1462 4.18312 59.3349 3.76323 59.3349 3.2583C59.3349 2.75337 59.1462 2.32816 58.7687 1.97737C58.3913 1.62657 57.9599 1.45117 57.4692 1.45117Z"
      fill="white"
    />
    <path
      d="M56.0029 20.1706C56.0029 20.4629 56.2456 20.7021 56.5421 20.7021H58.397C58.6936 20.7021 58.9363 20.4629 58.9363 20.1706V6.49489C58.9363 6.20256 58.6936 5.96338 58.397 5.96338H56.5421C56.2456 5.96338 56.0029 6.20256 56.0029 6.49489V20.1706Z"
      fill="white"
    />
    <path
      d="M75.6413 6.35709C74.73 5.46947 73.4736 5.02832 71.8721 5.02832C70.7614 5.02832 69.7746 5.23029 68.9226 5.63424C68.0653 6.03819 66.879 7.04805 66.879 7.04805C66.6525 7.23939 66.4207 7.15967 66.3667 6.87266L66.1457 5.77775C66.0863 5.49074 65.7952 5.25687 65.4986 5.25687H64.2746C63.978 5.25687 63.7354 5.49605 63.7354 5.78838V20.171C63.7354 20.4633 63.978 20.7025 64.2746 20.7025H66.1295C66.4261 20.7025 66.6687 20.4633 66.6687 20.171V9.99792C66.6687 9.70559 66.8628 9.3229 67.1001 9.1475C67.1001 9.1475 69.478 7.39353 71.0364 7.39353C72.0609 7.39353 72.8211 7.66992 73.3172 8.23331C73.8187 8.7914 73.9912 9.9554 73.9912 9.9554C74.0344 10.2424 74.0667 10.7208 74.0667 11.0131V20.171C74.0667 20.4633 74.3094 20.7025 74.6059 20.7025H76.4608C76.7574 20.7025 77.0001 20.4633 77.0001 20.171V10.5879C77.0001 10.2956 76.9623 9.82252 76.9138 9.53019C76.9192 9.53019 76.5471 7.24471 75.6413 6.35709Z"
      fill="white"
    />
  </svg>
);

export default Logo;
