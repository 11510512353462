import React, { useContext } from 'react';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import { StoreContext } from '../../store';
import { logout } from '../../services';

export default function Nav() {
  const { user, resetAuth, isAuthenticated } = useContext(StoreContext);

  const handleOnClick = () => {
    logout();
    resetAuth();
  };

  return (
    <nav>
      <Box bg="secondary" px={4}>
        <Flex h={16} alignItems="center" justifyContent="space-between">
          <Box>
            <Logo />
          </Box>
          {isAuthenticated && (
            <Flex alignItems="center">
              <svg
                width="32"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16" cy="16" r="16" fill="#FEFDFC" />
                <path
                  d="M18.813 17.5c1.145 0 2.13.412 2.953 1.234.823.823 1.234 1.808 1.234 2.953v.813c0 .417-.146.77-.438 1.063A1.446 1.446 0 0 1 21.5 24h-11c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 9 22.5v-.813c0-1.145.411-2.13 1.234-2.953s1.808-1.234 2.954-1.234c.27 0 .671.083 1.203.25a5.347 5.347 0 0 0 3.234 0c.542-.167.938-.25 1.188-.25Zm2.687 5v-.813c0-.729-.266-1.359-.797-1.89-.531-.531-1.161-.797-1.89-.797-.063 0-.391.083-.985.25A6.73 6.73 0 0 1 16 19.5a6.73 6.73 0 0 1-1.828-.25c-.594-.167-.922-.25-.985-.25-.729 0-1.359.266-1.89.797-.531.531-.797 1.161-.797 1.89v.813h11Zm-2.313-6.813C18.313 16.564 17.25 17 16 17c-1.25 0-2.313-.438-3.188-1.313-.874-.874-1.312-1.937-1.312-3.187 0-1.25.438-2.313 1.313-3.188C13.687 8.438 14.75 8 16 8c1.25 0 2.313.438 3.188 1.313.875.874 1.312 1.937 1.312 3.187 0 1.25-.438 2.313-1.313 3.188Zm-1.062-5.312A2.893 2.893 0 0 0 16 9.5c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 13 12.5c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 16 15.5c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 19 12.5c0-.833-.292-1.542-.875-2.125Z"
                  fill="#1A1B24"
                />
              </svg>
              <Stack direction="row" spacing={7}>
                <Menu closeOnBlur>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    bg="transparent"
                    color="primary"
                    fontWeight="normal"
                    fontSize={14}
                    className="user-menu"
                    textDecoration="underline"
                    lineHeight="tight"
                  >
                    {user?.nickname}
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title="Perfil">
                      <MenuItem>
                        <Link to="/dashboard">Inicio</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/dashboard/invoices">Listado de pagos</Link>
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem>
                        <Button
                          bg="transparent"
                          color="primary"
                          fontSize={14}
                          fontWeight="normal"
                          onClick={handleOnClick}
                        >
                          Cerrar sesión
                        </Button>
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Stack>
            </Flex>
          )}
        </Flex>
      </Box>
    </nav>
  );
}
