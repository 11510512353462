import React from 'react';

const Logo = () => (
  <svg
    width="100"
    height="35"
    viewBox="0 0 100 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.47413 22.0564C6.24809 22.2838 6.22492 22.6743 6.42777 22.925L13.0814 31.307C13.2785 31.5576 13.7016 31.7616 14.0203 31.7616H17.0168C17.3355 31.7616 17.4341 31.5576 17.237 31.307L8.74031 20.5934C8.54325 20.3427 8.19549 20.3252 7.96945 20.5526L6.47413 22.0564Z"
      fill="#011D1B"
    />
    <path
      d="M9.53417 16.985C9.53417 16.985 9.71387 16.7926 9.93411 16.5595L16.4892 9.48313C16.7036 9.24998 16.6225 9.05762 16.3037 9.05762H13.3768C13.0581 9.05762 12.6176 9.24998 12.4031 9.48313L3.69197 18.9027C3.47752 19.1359 3.29786 19.0659 3.29786 18.7453V9.64051C3.29786 9.31992 3.03705 9.05762 2.71828 9.05762H0.579585C0.260813 9.05762 0 9.31992 0 9.64051V25.9791C0 26.2996 0.185473 26.3754 0.411511 26.1481L9.11689 17.393C9.34293 17.1657 9.52838 16.9792 9.53417 16.985Z"
      fill="#011D1B"
    />
    <path
      d="M0.00585938 31.1785C0.00585938 31.4991 0.266673 31.7614 0.585444 31.7614H2.72411C3.04288 31.7614 3.30369 31.4991 3.30369 31.1785V25.8334C3.30369 25.5128 3.11825 25.437 2.89221 25.6643L0.41737 28.1533C0.191332 28.3806 0.00585938 28.8294 0.00585938 29.15V31.1785Z"
      fill="#011D1B"
    />
    <path
      d="M32.2309 16.7343C31.5006 16.0115 30.6428 15.4753 29.6517 15.1139C28.6606 14.7525 27.5768 14.5718 26.4118 14.5718C25.2411 14.5718 24.1573 14.77 23.1488 15.1663C22.1461 15.5627 21.2651 16.1398 20.5174 16.8975C19.764 17.6553 19.1728 18.5879 18.7439 19.6896C18.315 20.7971 18.1006 22.0561 18.1006 23.4726C18.1006 24.8657 18.3092 26.0956 18.7265 27.1681C19.1438 28.2348 19.7176 29.1383 20.4479 29.8728C21.1782 30.6072 22.036 31.1551 23.0271 31.5282C24.0181 31.9012 25.102 32.0878 26.2669 32.0878C27.4377 32.0878 28.5215 31.8896 29.53 31.4932C30.5327 31.0968 31.4137 30.5139 32.1613 29.7445C32.9148 28.9751 33.5059 28.0308 33.9348 26.9175C34.3637 25.7983 34.5782 24.5335 34.5782 23.117C34.5782 21.7239 34.3695 20.494 33.9522 19.4215C33.5349 18.3489 32.9611 17.4513 32.2309 16.7343ZM30.8688 26.3346C30.5964 27.139 30.2313 27.786 29.7734 28.2815C29.3213 28.7769 28.7939 29.1208 28.1969 29.3248C27.6 29.5289 26.9914 29.6338 26.3712 29.6338C25.7511 29.6338 25.1425 29.5114 24.5456 29.2724C23.9486 29.0334 23.4154 28.6603 22.9517 28.1532C22.488 27.6461 22.1113 26.9933 21.8215 26.1889C21.5375 25.3845 21.3926 24.4169 21.3926 23.2861C21.3926 22.062 21.5317 21.0477 21.8041 20.2433C22.0765 19.439 22.4417 18.7978 22.8995 18.314C23.3516 17.836 23.879 17.4979 24.476 17.3056C25.073 17.1132 25.6816 17.0199 26.3017 17.0199C26.9219 17.0199 27.5304 17.1365 28.1274 17.3638C28.7244 17.5912 29.2576 17.9584 29.7213 18.4655C30.1849 18.9726 30.5617 19.6196 30.8515 20.4124C31.1355 21.2051 31.2803 22.1669 31.2803 23.2977C31.2861 24.516 31.147 25.5302 30.8688 26.3346Z"
      fill="#011D1B"
    />
    <path
      d="M38.5537 31.1787C38.5537 31.4993 38.8145 31.7616 39.1333 31.7616H41.0575C41.3763 31.7616 41.6371 31.4993 41.6371 31.1787V9.96717C41.6371 9.64658 41.3763 9.38428 41.0575 9.38428H39.1333C38.8145 9.38428 38.5537 9.64658 38.5537 9.96717V31.1787Z"
      fill="#011D1B"
    />
    <path
      d="M54.3944 29.0047C53.8206 29.1154 53.2932 29.1679 52.8179 29.1679C52.1514 29.1679 51.6066 29.0222 51.1893 28.7366C50.772 28.4509 50.6503 27.6174 50.6503 27.6174C50.6039 27.3026 50.5633 26.778 50.5633 26.4574V17.784C50.5633 17.4634 50.8241 17.2011 51.1429 17.2011H54.9276C55.2464 17.2011 55.5072 16.9388 55.5072 16.6182V15.4058C55.5072 15.0852 55.2464 14.8229 54.9276 14.8229H51.1429C50.8241 14.8229 50.5633 14.5606 50.5633 14.24V10.0023C50.5633 9.68174 50.3025 9.41943 49.9837 9.41943H48.0247C47.706 9.41943 47.4451 9.68174 47.4451 10.0023V14.24C47.4451 14.5606 47.1843 14.8229 46.8656 14.8229H45.5151C45.1964 14.8229 44.9355 15.0852 44.9355 15.4058V16.6182C44.9355 16.9388 45.1964 17.2011 45.5151 17.2011H46.8656C47.1843 17.2011 47.4451 17.4634 47.4451 17.784V26.8888C47.4451 27.2094 47.4857 27.7282 47.5321 28.0487C47.5321 28.0487 47.8219 29.9315 48.5869 30.6601C49.352 31.3946 50.5691 31.7618 52.2441 31.7618C53.0555 31.7618 53.8322 31.6918 54.574 31.5461C55.3159 31.4004 55.8491 31.2255 55.8491 31.2255C56.1505 31.1264 56.3476 30.7883 56.2838 30.4736L56.0172 29.2029C55.9534 28.8881 55.6463 28.7074 55.3391 28.7948C55.3391 28.7832 54.9681 28.8939 54.3944 29.0047Z"
      fill="#011D1B"
    />
    <path
      d="M61.772 10.6489C61.2214 10.6489 60.7519 10.8413 60.3578 11.226C59.9637 11.6107 59.7666 12.077 59.7666 12.6308C59.7666 13.1845 59.9637 13.645 60.3578 14.0181C60.7519 14.3911 61.2214 14.5776 61.772 14.5776C62.2994 14.5776 62.7631 14.3911 63.1688 14.0181C63.5745 13.645 63.7773 13.1845 63.7773 12.6308C63.7773 12.077 63.5745 11.6107 63.1688 11.226C62.7631 10.8413 62.2994 10.6489 61.772 10.6489Z"
      fill="#011D1B"
    />
    <path
      d="M60.1953 31.1789C60.1953 31.4995 60.4561 31.7618 60.7749 31.7618H62.7687C63.0874 31.7618 63.3482 31.4995 63.3482 31.1789V16.181C63.3482 15.8604 63.0874 15.5981 62.7687 15.5981H60.7749C60.4561 15.5981 60.1953 15.8604 60.1953 16.181V31.1789Z"
      fill="#011D1B"
    />
    <path
      d="M81.3041 16.029C80.3246 15.0556 78.9742 14.5718 77.2528 14.5718C76.0589 14.5718 74.9982 14.7933 74.0825 15.2363C73.1609 15.6793 71.8858 16.7868 71.8858 16.7868C71.6424 16.9966 71.3932 16.9092 71.3352 16.5944L71.0976 15.3937C71.0338 15.0789 70.7209 14.8224 70.4021 14.8224H69.0864C68.7676 14.8224 68.5068 15.0847 68.5068 15.4053V31.1784C68.5068 31.499 68.7676 31.7613 69.0864 31.7613H71.0802C71.399 31.7613 71.6598 31.499 71.6598 31.1784V20.0218C71.6598 19.7012 71.8685 19.2816 72.1235 19.0892C72.1235 19.0892 74.6794 17.1657 76.3544 17.1657C77.4557 17.1657 78.2729 17.4688 78.8061 18.0866C79.3451 18.6987 79.5306 19.9752 79.5306 19.9752C79.5769 20.29 79.6117 20.8146 79.6117 21.1352V31.1784C79.6117 31.499 79.8725 31.7613 80.1913 31.7613H82.185C82.5038 31.7613 82.7646 31.499 82.7646 31.1784V20.6689C82.7646 20.3483 82.7241 19.8295 82.6719 19.5089C82.6777 19.5089 82.2778 17.0024 81.3041 16.029Z"
      fill="#011D1B"
    />
    <path
      d="M87.6362 10.2854C86.4899 11.384 85.9406 12.7214 85.9406 14.2976C85.9406 15.8739 86.4899 17.2113 87.6362 18.3099C88.7587 19.4085 90.1439 19.9578 91.7918 19.9578C93.4158 19.9578 94.8009 19.4085 95.9473 18.3099C97.0459 17.2113 97.6191 15.8739 97.6191 14.2976C97.6191 12.7214 97.0459 11.384 95.9473 10.2854C94.8009 9.18682 93.4158 8.63753 91.7918 8.63753C90.1439 8.63753 88.7587 9.18682 87.6362 10.2854ZM91.7918 18.8353C90.4543 18.8353 89.3319 18.4054 88.4721 17.5218C87.5646 16.6381 87.1347 15.5634 87.1347 14.2976C87.1347 13.0319 87.5646 11.9572 88.4721 11.0496C89.3319 10.1899 90.4543 9.73612 91.7918 9.73612C93.1053 9.73612 94.2039 10.1899 95.0875 11.0496C95.9712 11.9572 96.4249 13.0319 96.4249 14.2976C96.4249 15.5634 95.9712 16.6381 95.0875 17.5218C94.2039 18.4054 93.1053 18.8353 91.7918 18.8353ZM94.0606 15.444C94.0128 14.8708 93.774 14.4887 93.3441 14.3215C93.9173 14.0827 94.2278 13.6051 94.2278 12.8886C94.2278 12.3632 94.0367 11.9333 93.6546 11.6467C93.2725 11.3601 92.7709 11.1929 92.1022 11.1929H89.5229V17.2591H90.5738V14.8231H92.2216C92.5082 14.8231 92.6993 14.8947 92.8187 15.0141C92.9381 15.1574 93.0098 15.3724 93.0575 15.7067L93.1053 16.3276C93.1053 16.7575 93.2008 17.068 93.3441 17.2591H94.4188C94.2278 17.0202 94.1322 16.7098 94.1322 16.3276L94.0606 15.444ZM90.5738 13.8678V12.1482H92.1022C92.7948 12.1482 93.1531 12.4348 93.1531 13.008C93.1531 13.5812 92.8187 13.8678 92.1978 13.8678H90.5738Z"
      fill="#011D1B"
    />
  </svg>
);

export default Logo;
