import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { Spinner } from '@chakra-ui/react';
import { logout, webAuth } from '../services';
import { StoreContext } from '../store';

const Authorize = () => {
  const { authenticateUser, setUserData, isAuthenticated, resetAuth } =
    useContext(StoreContext);

  const parseAuthToken = () => {
    if (window.location.hash) {
      webAuth.parseHash({ hash: window.location.hash }, (err, res) => {
        if (err) {
          resetAuth();
          logout();
          return;
        }

        const {
          name: phoneNumber,
          nickname,
          picture,
          sub: id,
        } = res.idTokenPayload;

        const { idToken, accessToken } = res;

        setUserData({ phoneNumber, nickname, picture, id });
        authenticateUser({
          idToken,
          accessToken,
        });
      });
    }
  };

  useEffect(parseAuthToken, [authenticateUser, resetAuth, setUserData]);

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <Spinner />;
};

export default Authorize;
