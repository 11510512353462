import React, { createContext, useReducer } from 'react';
import storage from 'store2';

const initialState = {
  isAuthenticated: storage.get('isAuthenticated') ?? false,
  idToken: storage.get('idToken') ?? undefined,
  accessToken: storage.get('accessToken') ?? undefined,
  user: storage.get('user') ?? undefined,
};

const types = {
  AUTHENTICATE_USER: 'AUTHENTICATE_USER',
  SET_USER_DATA: 'SET_USER_DATA',
  RESET_AUTH: 'RESET_AUTH',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.AUTHENTICATE_USER:
      storage.set('idToken', action.payload.idToken);
      storage.set('accessToken', action.payload.accessToken);
      storage.set('isAuthenticated', true);
      return {
        ...state,
        isAuthenticated: true,
        idToken: action.payload.idToken,
        accessToken: action.payload.accessToken,
      };
    case types.SET_USER_DATA:
      storage.set('user', action.payload);
      return {
        ...state,
        user: action.payload,
      };
    case types.RESET_AUTH:
      storage.clearAll();
      return {
        ...state,
        isAuthenticated: false,
        idToken: undefined,
        accessToken: undefined,
        user: undefined,
      };
    default:
      return state;
  }
};

const StoreContext = createContext(initialState);

// eslint-disable-next-line react/prop-types
const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatches = {
    authenticateUser(authenticationData) {
      dispatch({
        type: types.AUTHENTICATE_USER,
        payload: authenticationData,
      });
    },
    setUserData(data) {
      dispatch({
        type: types.SET_USER_DATA,
        payload: data,
      });
    },
    resetAuth() {
      dispatch({
        type: types.RESET_AUTH,
      });
    },
    userIsAuthenticated() {
      dispatch({
        type: types.USER_IS_AUTHENTICATED,
      });
    },
    setInitialStateFromLocalStorage() {
      dispatch({
        type: types.SET_INITIAL_STATE_FROM_LOCAL_STORAGE,
      });
    },
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StoreContext.Provider value={{ ...state, ...dispatches }}>
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
