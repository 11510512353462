import React from 'react';
import { Box, HStack, Text, Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { ArrowDownIcon } from '@chakra-ui/icons';
import { saveAs } from 'file-saver';

const InvoiceListItem = ({ invoiceItem, invoiceAmount, invoiceDate }) => {
  const getFormattedDate = () => {
    const formattedDate = new Date(invoiceDate);

    return formattedDate.toLocaleDateString();
  };

  const downloadInvoice = () => {
    saveAs(
      'https://koltin-client-staging.s3.amazonaws.com/recibo_2286_3978.pdf',
      'recibo_2286_397r.pdf'
    );
  };

  return (
    <Box w="100%" borderBottom="1px" borderBottomColor="gray.100" h="60px">
      <HStack>
        <Box w="80%">{invoiceItem}</Box>
        <Box w="20%">${invoiceAmount}</Box>
      </HStack>
      <HStack>
        <Box w="50%">
          <Text fontSize="xs" color="gray.500">
            {getFormattedDate()}
          </Text>
        </Box>
        <Box textAlign="right" w="50%">
          <Button
            variant="solid"
            bg="primary"
            color="white"
            leftIcon={<ArrowDownIcon />}
            size="xs"
            _active={{
              bg: 'primary',
            }}
            _hover={{
              bg: 'primary',
            }}
            onClick={downloadInvoice}
          >
            Descargar
          </Button>
        </Box>
      </HStack>
    </Box>
  );
};

InvoiceListItem.propTypes = {
  invoiceItem: PropTypes.string,
  invoiceAmount: PropTypes.string,
  invoiceDate: PropTypes.any,
};

export default InvoiceListItem;
