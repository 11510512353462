import React from 'react';

const HeartIcon = () => (
  <svg width="26" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m17.885 15.918-4.659 4.843a.778.778 0 0 1-1.122 0l-4.659-4.843M1.748 9.722a5.85 5.85 0 0 1-.127-5.477v0a5.868 5.868 0 0 1 1.777-2.1 5.894 5.894 0 0 1 5.292-.856 5.886 5.886 0 0 1 2.353 1.433l1.623 1.613 1.624-1.618a5.886 5.886 0 0 1 5.102-1.641 5.868 5.868 0 0 1 4.32 3.164v0a5.853 5.853 0 0 1-.124 5.477"
      stroke="#F16158"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 12.819h6.76a.39.39 0 0 0 .348-.214l1.786-3.562a.389.389 0 0 1 .6-.131c.06.05.104.117.126.193l1.743 5.787a.389.389 0 0 0 .573.213.39.39 0 0 0 .153-.172l1.628-3.786a.388.388 0 0 1 .525-.197.39.39 0 0 1 .157.134l1.157 1.73h7.777"
      stroke="#F16158"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HeartIcon;
