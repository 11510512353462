import React from 'react';
import {
  Box,
  Center,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Logo from './Logo';

export default function Footer() {
  return (
    <Box
      bg="primary"
      style={{ position: 'fixed', left: 0, bottom: 0, right: 0 }}
      color={useColorModeValue('white')}
      h="150px"
    >
      <Container
        as={Stack}
        maxW="6xl"
        py={4}
        spacing={4}
        justify="center"
        align="center"
      >
        <Logo />
        <Stack direction="row" spacing={6}>
          <Link href="#">Privacy Policy</Link>
          <Link href="#">Terms of Use</Link>
        </Stack>
      </Container>
      <Center width="100%">
        <Text fontSize="sm">
          Copyright 2022 © Koltin company. All right reserved
        </Text>
      </Center>
    </Box>
  );
}
