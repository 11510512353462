import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  List,
  ListItem,
  ListIcon,
  IconButton,
} from '@chakra-ui/react';

import { MdCheckCircle } from 'react-icons/all';
import { ArrowDownIcon } from '@chakra-ui/icons';
import { saveAs } from 'file-saver';
import Layout from '../components/Layout/Layout';
import { StoreContext } from '../store';
import CircleIcon from '../styles/icons/CircleIcon';
import { getPolicies } from '../requests/Policy';

const Dashboard = () => {
  const { user } = useContext(StoreContext);

  const [policyCardState, setPolicyCardState] = useState({
    policies: undefined,
  });

  useEffect(() => {
    async function fetchData() {
      if (typeof policyCardState.policies === 'undefined') {
        const response = await getPolicies(user.phoneNumber);
        const data = await response.json();
        setPolicyCardState({ ...policyCardState, policies: data });
      }
    }

    const addScript = ({ src, id, onLoad }) => {
      const existing = document.getElementById(id);
      if (existing) {
        return existing;
      }
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.async = true;
      script.defer = true;
      script.setAttribute('bmid', 'ac76a3529b6640e3bd2d8c1569ab58f1');
      script.onload = () => {
        if (onLoad) {
          onLoad();
        }
      };
      document.head.appendChild(script);
      return script;
    };
    fetchData();
    addScript({
      src: `https://widget.botlers.io/sdk/main.js`,
      id: 'botlers-messaging-sdk-v2',
      onLoad: () => {
        console.log('AN script loaded!');
      },
    });
  }, [policyCardState, user]);

  const downloadCertificate = () => {
    saveAs(
      'https://koltin-client-staging.s3.amazonaws.com/certificado_300100001_1649224362.pdf',
      'certificado_300100001_1649224362.pdf'
    );
  };

  return (
    <Layout showUpperPanel={false} showFooter={false}>
      <Box
        width="100%"
        maxWidth="36rem"
        textAlign="center"
        paddingY="2rem"
        padding="15px"
      >
        <Box w="100%" textAlign="left" paddingY="30px">
          <Heading>Mis pólizas</Heading>
        </Box>
        {typeof policyCardState.policies !== 'undefined' && (
          <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            bg="gray.250"
            padding="20px"
          >
            <Stack direction={['column']}>
              <Box>
                <Stack direction={['row']} spacing="24px">
                  <Box textAlign="left" w="50%">
                    <Text fontSize="xs" color="primary">
                      Tu tipo de Seguro
                    </Text>
                    <Text fontSize="sm" color="primary">
                      {policyCardState.policies[0]?.plan}
                    </Text>
                  </Box>
                  <Box textAlign="right" w="50%">
                    <Tag size="md" variant="subtle" colorScheme="green">
                      <TagLeftIcon
                        boxSize="12px"
                        as={CircleIcon}
                        color="green.150"
                      />
                      <TagLabel color="brown.950">
                        {policyCardState.policies[0]?.status}
                      </TagLabel>
                    </Tag>
                  </Box>
                </Stack>
              </Box>
              <Box w="100%" textAlign="left" paddingY="20px">
                <Heading as="h3" size="md">
                  {policyCardState.policies[0]?.beneficiary.fullname}
                </Heading>
              </Box>
            </Stack>
            <Stack direction="row" spacing="24px">
              <Box w="40%" h="40px" textAlign="left">
                <Text fontSize="xs">No. de póliza</Text>
                <Text fontSize="sm">{policyCardState.policies[0]?.number}</Text>
              </Box>
              <Box w="40%" h="40px" textAlign="left">
                <Text fontSize="xs">Válido hasta</Text>
                <Text fontSize="sm">
                  {policyCardState.policies[0]?.period.ends_at}
                </Text>
              </Box>
              <Box textAlign="center">
                <IconButton
                  variant="solid"
                  bg="primary"
                  color="white"
                  icon={<ArrowDownIcon />}
                  size="xs"
                  _active={{
                    bg: 'primary',
                  }}
                  _hover={{
                    bg: 'primary',
                  }}
                  onClick={downloadCertificate}
                  aria-label="Descargar certificado"
                  fontSize="20px"
                />
              </Box>
            </Stack>
          </Box>
        )}
      </Box>
      {typeof policyCardState.policies !== 'undefined' && (
        <Box
          width="100%"
          maxWidth="36rem"
          textAlign="left"
          paddingY="2rem"
          padding="15px"
        >
          <Box w="100%" textAlign="left" paddingBottom="30px">
            <Heading>Mis coberturas</Heading>
          </Box>
          <List
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            padding="20px"
            spacing={3}
          >
            {policyCardState.policies[0]?.coverages.benefits.map(
              (item, index) => (
                <ListItem key={index} fontSize="sm" color="gray.50">
                  <ListIcon as={MdCheckCircle} color="orange.150" />
                  {item}
                </ListItem>
              )
            )}
          </List>
        </Box>
      )}
    </Layout>
  );
};

export default Dashboard;
