import React, { useState, useEffect } from 'react';
import { Heading, VStack, Box, Text } from '@chakra-ui/react';
import Layout from '../components/Layout/Layout';
import { getInvoices } from '../requests/Invoice';
import InvoiceListItem from '../components/InvoiceListItem/InvoiceListItem';

const InvoiceHistory = () => {
  const [invoicesState, setInvoicesState] = useState({
    invoices: [],
  });

  useEffect(() => {
    async function fetchData() {
      if (invoicesState.invoices.length === 0) {
        const response = await getInvoices('5554762014');
        const data = await response.json();
        setInvoicesState({ ...invoicesState, invoices: data });
      }
    }
    fetchData();
  }, [invoicesState]);

  return (
    <Layout showUpperPanel={false} showFooter={false}>
      <VStack padding="15px" height="80vh">
        <Box textAlign="left" w="100%" paddingY="30px">
          <Heading>Historial de pagos</Heading>
        </Box>
        <Box
          w="full"
          boxShadow="2xl"
          rounded="md"
          overflow="hidden"
          padding="10px"
          bg="gray.250"
        >
          <VStack w="100%">
            <VStack w="100%">
              {invoicesState.invoices.length === 0 && (
                <Text>Sin datos para mostrar</Text>
              )}
              {invoicesState.invoices.map((invoice, index) => (
                <InvoiceListItem
                  key={index}
                  invoiceItem={invoice.product_name}
                  invoiceAmount={invoice.amount}
                  invoiceDate={invoice.date}
                />
              ))}
            </VStack>
          </VStack>
        </Box>
      </VStack>
    </Layout>
  );
};

export default InvoiceHistory;
