import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import koltinTheme from './styles/theme';
import Fonts from './styles/Fonts';
import './index.css';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <ChakraProvider resetCSS theme={koltinTheme}>
    <Fonts />
    <App />
  </ChakraProvider>
);
